body {
  background-color: #fafafa !important;
  line-height: 1.43 !important;
  letter-spacing: 0.01071em !important;
  font-size: 0.875rem !important;
}

.custom-listItem {
  &:hover .custom-listItemIcon,
  &[data-selected='true'] .custom-listItemIcon {
    color: #303f9f;
  }
}
.disable-body-scroll {
  overflow-y: hidden;
}
